
*{
	padding: 0px;
	margin: 0px;
}
body {
	font-family: sans-serif;
}
nav {
	display: flex;
	align-items: center;
	background: #00A9D4;
	height: 60px;
	position: relative;
}
.icon {
	cursor: pointer;
    margin-top: -10px;
	margin-right: 30px;
	line-height: 40px;
}
.icon span {
	background: #f00;
	padding: 3px;
	border-radius: 50%;
	color: #fff;
	vertical-align: top;
	margin-left: -14px;
    font-size: x-small;
}
.icon img {
	display: inline-block;
	width: auto;
	margin-top: 20px;
}
.icon:hover {
	opacity: .7;
}

  .notification-box {
	position: fixed;
	top: 60px; /* Adjust according to your header */
	right: 20px;
	width: 350px;
	max-height: 70vh;
	background: linear-gradient(145deg, #ffffff, #f3f3f3);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: transform 0.3s ease, opacity 0.3s ease;
	transform: translateY(-20px);
	opacity: 0;
	z-index: 1000;
	pointer-events: none;
  }
  
  .notification-box.visible {
	transform: translateY(0);
	opacity: 1;
	pointer-events: auto;
  }
  
  .notification-header {
	background: #4d3faa;
	color: #fff;
	padding: 12px 16px;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .notification-header .mark-all-read {
	background: #fff;
	color: #4d3faa;
	border: none;
	padding: 5px 10px;
	font-size: 12px;
	border-radius: 8px;
	cursor: pointer;
	transition: background 0.3s ease;
  }
  
  .notification-header .mark-all-read:hover {
	background: #ddd;
  }
  
  .notification-content {
	flex: 1;
	overflow-y: auto;
	padding: 10px;
  }
  
  .notification-item {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	padding: 10px;
	border-bottom: 1px solid #e0e0e0;
	transition: background 0.3s ease;
	cursor: pointer;
  }
  
  .notification-item:hover {
	background: #f9f9f9;
  }
  
  .notification-item.unread h4 {
	font-weight: bold;
  }
  
  .notification-icon {
	width: 10px;
	height: 10px;
	margin-top: 5px;
  }
  
  .notification-text h4 {
	margin: 0;
	font-size: 14px;
	color: #333;
	text-align: left;
  }
  
  .notification-text p {
	margin: 4px 0 0;
	font-size: 12px;
	color: #666;
	text-align: left;
  }
  
  .notification-date {
	font-size: 10px;
	color: #aaa;
	margin-left: auto;
  }
  
  .empty-message {
	text-align: center;
	color: #999;
	font-size: 14px;
	padding: 20px 0;
  }
  
  .notification-footer {
	text-align: center;
	padding: 10px;
	border-top: 1px solid #e0e0e0;
  }
  
  .notification-footer a {
	color: #4d3faa;
	font-size: 14px;
	text-decoration: none;
	transition: color 0.3s ease;
  }
  
  .notification-footer a:hover {
	color: #3a2d91;
  }

  
  /* Case Transfer CardComponent.css */

  .card-container {
  	border: 1px solid #ddd;
  	border-radius: 8px;
  	padding: 5px;
  	max-width: 300px;
  	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  	margin: 10px auto;
  	display: flex;
  }

  .card-image {
  	width: 50px;
  	height: 50px;
  	border-radius: 50%;
  	margin-right: 0;
  }

  .card-title {
  	text-align: left;
  	margin: 0 7px 0;
  	font-size: 14px;
  	color: #333;
  }

  .card-subtitle {
  	text-align: left;
  	margin: 5px 15px 0;
  	font-size: 14px;
  	color: #333;
  }

  .card-grade {
  	text-align: left;
  	margin: 0 15px 8px;
  	color: #555;
  	font-size: 12px;
  }

  .cancel-button {
  	border: 1px solid red;
  	color: #dc3545;
  	border-radius: 15px;
  	padding: 5px 10px;
  	margin: 5px;
  	cursor: pointer;
  	margin-left: -4rem;
  	transition: background-color 0.3s, color 0.3s;
  	font-size: 14px;
  }

  .cancel-button:hover {
  	background-color: red;
  	color: white;
  }

  .transfer-button {
  	border: 1px solid green;
  	color: #28a745;
  	border-radius: 15px;
  	padding: 5px 10px;
  	margin: 5px;
  	cursor: pointer;
  	transition: background-color 0.3s, color 0.3s;
  	font-size: 14px;
  }

  .transfer-button:hover {
  	background-color: #28a745;
  	color: white;
  }

  .cancel-button-web {
  	border: 1px solid red;
  	color: #dc3545;
  	border-radius: 15px;
  	padding: 5px 10px;
  	margin: 5px;
  	cursor: pointer;
  	transition: background-color 0.3s, color 0.3s;
  	font-size: 14px;
  }

  .cancel-button-web:hover {
  	background-color: red;
  	color: white;
  }